import React from "react"

function AddNew () {
  return (
    <div>
      
    </div>
  )
};

export default AddNew;
