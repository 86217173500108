import React from "react"

function NotFound () {
  return (
    <div>
      <h1> ERROR 404 :( Page Not Found </h1>
    </div>
  )
};

export default NotFound;
