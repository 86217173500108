import React from "react"

function Disputes () {
  return (
    <div>
      
    </div>
  )
};

export default Disputes;
