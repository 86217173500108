import React from "react"

function Register () {
  return (
    <div>
      Registration Page
    </div>
  )
};

export default Register;
