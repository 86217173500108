import React from "react"
import DashDesign from "../components/DashDesign";

function UserDashboard () {
  return (
    <>
    <DashDesign/>
    </>
  )
};

export default UserDashboard;
